import "./style.scss";

import PropTypes from "prop-types";
import { createClassName } from "../../../helpers";

const cn = createClassName("supportMail");

export const SupportMail = (props) => (
  <a className={cn()} href="mailto:support@xltools.net">
    {props.text}
  </a>
);

SupportMail.defaultProps = {
  text: "support@xltools.net",
};

SupportMail.propTypes = {
  text: PropTypes.string,
};
